.library {
  table {
    td {
      vertical-align: middle;
    }
  }

  form {
    justify-content: center;
    margin-bottom: 2rem;
  }
  label {
    margin-right: 0.5rem;
  }
  input,
  select {
    margin-right: 1rem;
  }
}
