.login-page {
  max-width: 600px;
  margin: 15% auto 0;

  .logo {
    max-width: 75%;
  }

  label {
    font-weight: 300;
    letter-spacing: 0.5px;
  }
}
